.mainContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-top: 70vh;
	padding-inline: 15vw;
}

.cover {
	top: 0px;
	position: fixed;
	object-fit: cover;
	width: 100vw;
	height: 100vh;
}

.coverOverlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 20vh;
	background: linear-gradient(
		to top,
		rgba(0, 0, 0, 0),
		var(--blacks-primary)
	); /* Adjust the gradient as needed */
	pointer-events: none; /* Allows clicks to pass through the overlay to the image */
}

.body {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	z-index: 1;
	background-color: var(--blacks-primary);
	box-shadow: 0px 21px 35px 31px rgba(0, 0, 0, 0.28);
	padding-bottom: 10vh;
	padding-top: 5vh;
	backdrop-filter: blur(30px) brightness(70%);
	background-color: var(---blacks-primary-half-transparent);
}

.header {
	color: var(--browns-primaryLight);
	margin-top: auto;
	text-align: center;
}

.bodyText {
	color: var(--whites-primary);
	width: 90%;
	line-height: 180%;
}

.showroom {
	width: 90%;
	object-fit: contain;
	margin-top: 2vh;
}

@media screen and (max-width: 1024px) {
	.mainContainer {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding-top: 30vh;
		padding-inline: 3vw;
	}

	.cover {
		top: 0px;
		position: fixed;
		object-fit: cover;
		width: 100vw;
		height: 50vh;
	}

	.coverOverlayBottom {
		position: fixed;
		top: 30vh;
		left: 0;
		width: 100%;
		height: 20vh;
		background: linear-gradient(
			to bottom,
			rgba(0, 0, 0, 0),
			var(--blacks-primary)
		);
		pointer-events: none;
	}
}
