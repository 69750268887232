.mainContainer {
	display: flex;
	justify-content: center;
	background-color: var(--blacks-primary);
}

.gridView {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
	align-content: flex-start;
	width: 75vw;
	gap: 10px;
	row-gap: 10vh;
	padding-top: 10vh;
}

.listView {
	display: flex;
	flex-direction: column;
	width: 90vw;
	flex-wrap: nowrap;
	padding-top: 10vh;
}
