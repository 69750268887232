.container {
	flex: 0 0 calc(33.33% - 10px);
	height: calc(33.33vw - 10px);
	/* aspect-ratio: ; */
	overflow: hidden;
	position: relative;
	cursor: pointer;
}

.mainContainer {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	transition: filter 0.3s ease;
	z-index: 0;
}

.mainContainer:hover {
	filter: blur(8px) brightness(30%);
	background-color: var(---blacks-primary-half-transparent);
}

.contentContainer {
	position: relative;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	align-items: center;
	padding-left: 5%;
	padding-right: 5%;
	padding-top: 20%;
	opacity: 0;
	transition:
		padding 0.3s ease,
		opacity 0.3s ease;
	pointer-events: none;
	z-index: 1;
}

.contentContainerHovered {
	position: relative;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	align-items: center;
	padding-left: 5%;
	padding-right: 5%;
	padding-top: 0%;
	opacity: 1;
	pointer-events: none;
	transition:
		padding 0.3s ease,
		opacity 0.3s ease;
	z-index: 1;
}

.title {
	color: var(--browns-primaryLight);
	font-weight: 400;
	line-height: 56px;
	pointer-events: none;
	text-align: center;
}

.description {
	color: white;
	pointer-events: none;
	text-align: left;
	width: 100%;
	text-align: center;
}

@media screen and (max-width: 1024px) {
	.mainContainer {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		transition: filter 0.3s ease;
		z-index: 0;
		pointer-events: none;
	}
}
