.mainContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding-top: 20vh;
}

.imageContainer {
	flex: 1;
	min-width: calc(50% - 6.35rem);
	min-height: 50vh;
	padding-top: 2vh;
	padding-bottom: 5vh;
	border-left-width: 1px;
	border-left-style: solid;
	border-left-color: var(--browns-primaryLight);
}

.textContainer {
	flex: 2;
	padding-right: 3vw;
}

.image {
	width: 100%;
	max-height: 50vh;
	object-fit: cover;
}

.tagline {
	color: var(--browns-primaryLight);
}

.header {
	color: var(--whites-primary);
	margin-top: 1vh;
	margin-bottom: 1vh;
}

.textColor {
	color: var(--whites-primary);
}

.bulletedText {
	color: var(--whites-primary);
	margin-top: 0%;
}

.entriesContainer {
	padding-top: 3vh;
}

.entryContainer {
	display: flex;
	flex: 1;
	flex-direction: row;
	align-items: flex-start;
	padding-right: 1rem;
}

.icon {
	object-fit: contain;
	height: 2rem;
	width: 2rem;
	margin-right: 1rem;
}

@media screen and (max-width: 1024px) {
	.image {
		width: 100%;
		max-height: 30vh;
		margin-bottom: 3vh;
		object-fit: cover;
	}

	.textContainer {
		flex: 2;
	}
}
