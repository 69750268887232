.mainContainer {
	display: flex;
	flex-direction: column;
	padding-top: 20vh;
}

.header {
	color: var(--browns-primaryLight);
}

.description {
	color: var(--whites-primary);
}

.projects {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: flex-start;
	gap: 10px;
}
