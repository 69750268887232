.header1 {
	font-family: "Bebas Neue";
	font-style: normal;
	font-weight: 400;
	font-size: 96px;
	line-height: 100%;
}

.header2 {
	font-family: "Bebas Neue";
	font-style: normal;
	font-weight: 300;
	font-size: 70px;
	line-height: 100%;
}

.header3 {
	font-family: "Bebas Neue";
	font-style: normal;
	font-weight: 200;
	font-size: 3rem;
	line-height: 100%;
}

p {
	font-family: "Catamaran";
	font-size: 1.1rem;
	font-weight: 400;
	line-height: 1.9rem;
	letter-spacing: 0.1rem;
}

li {
	font-family: "Catamaran";
	font-size: 1.2rem;
	font-weight: 300;
	line-height: 1.5rem;
	letter-spacing: 0.1rem;
}

ul {
	font-family: "Catamaran";
	font-size: 1.2rem;
	font-weight: 300;
	line-height: 1.5rem;
	letter-spacing: 0.1rem;
}

li {
	font-family: "Catamaran";
	font-size: 1.2rem;
	font-weight: 300;
	line-height: 1.5rem;
	letter-spacing: 0.1rem;
}

b {
	font-size: 1.2rem;
	font-family: "Catamaran";
	font-weight: 600;
	line-height: 23.4px;
}

h1 {
	font-family: "Bebas Neue";
	font-style: normal;
	font-weight: 400;
	font-size: 96px;
	line-height: 100%;
	margin: 0 0 0 0;
}

h2 {
	font-family: "Bebas Neue";
	font-style: normal;
	font-weight: 400;
	font-size: 3.5rem;
	line-height: 100%;
	margin: 0 0 0 0;
}

h3 {
	font-family: "Bebas Neue";
	font-style: normal;
	font-weight: 200;
	font-size: 2.5rem;
	line-height: 100%;
	margin: 0 0 0 0;
}

h4 {
	font-size: 1.8rem;
	font-family: Bebas Neue;
	font-weight: 400;
	letter-spacing: 1.5px;
	margin: 0 0 0 0;
}

h5 {
	font-size: 1.4rem;
	font-family: Bebas Neue;
	font-weight: 200;
	letter-spacing: 1.5px;
	margin: 0 0 0 0;
}

h6 {
	font-size: 1rem;
	font-family: Bebas Neue;
	font-weight: 200;
	letter-spacing: 1.5px;
	margin: 0 0 0 0;
}

a {
	font-family: "Catamaran";
	font-size: 1.2rem;
	font-weight: 600;
	font-style: italic;
	line-height: 1.5rem;
	letter-spacing: 0.1rem;
	text-decoration: underline;
}

a:link {
	color: var(--browns-primaryLight);
}

a:visited {
	color: var(--browns-primaryLight);
}

.menu {
	font-family: "Bebas Neue";
	font-style: normal;
	font-weight: 400;
	font-size: 1.7rem;
	line-height: 100%;
}

.body {
	font-family: "Catamaran";
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 130%;
}

.footNote {
	font-family: "Catamaran";
	font-style: normal;
	font-weight: 400;
	font-size: 10px;
	line-height: 150%;
}

@media screen and (max-width: 1024px) {
	.menu {
		font-family: "Bebas Neue";
		font-style: normal;
		font-weight: 400;
		font-size: 2.5rem;
		line-height: 100%;
	}

	.header1 {
		font-family: "Bebas Neue";
		font-style: normal;
		font-weight: 400;
		font-size: 4em;
		line-height: 100%;
	}

	.header2 {
		font-family: "Bebas Neue";
		font-style: normal;
		font-weight: 400;
		font-size: 3em;
		line-height: 100%;
	}

	.body {
		font-family: "Catamaran";
		font-style: normal;
		font-weight: 400;
		font-size: 1em;
		line-height: 130%;
	}

	h1 {
		font-family: "Bebas Neue";
		font-style: normal;
		font-weight: 400;
		font-size: 4em;
		line-height: 100%;
		margin: 0 0 0 0;
	}

	h2 {
		font-family: "Bebas Neue";
		font-style: normal;
		font-weight: 400;
		font-size: 3rem;
		line-height: 100%;
		margin: 0 0 0 0;
	}

	h3 {
		font-family: "Bebas Neue";
		font-style: normal;
		font-weight: 200;
		font-size: 2.3rem;
		line-height: 100%;
		margin: 0 0 0 0;
	}

	h4 {
		font-size: 1.9rem;
		font-family: Bebas Neue;
		font-weight: 400;
		letter-spacing: 1.5px;
	}

	h5 {
		font-size: 1.5rem;
		font-family: Bebas Neue;
		font-weight: 200;
		letter-spacing: 1.5px;
		margin: 0 0 0 0;
	}

	p {
		font-family: "Catamaran";
		font-size: 1rem;
		font-weight: 300;
		line-height: 1.5rem;
		font-size: 1.2rem;
	}

	b {
		font-size: 0.85rem;
		font-family: "Catamaran";
		font-weight: 600;
		line-height: 18px;
	}
}
