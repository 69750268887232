.mainContainer {
	display: flex;
	width: 100vw;
	height: 100vh;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: var(--blacks-primary);
}

.headerLogo {
	width: 8rem;
	height: 8rem;
	object-fit: contain;
}

.headerTitle {
	color: var(--browns-primaryLight);
	margin-bottom: 1rem;
}

.messageContainer {
	display: flex;
	flex-direction: column;
	width: 50%;
	padding-left: 1rem;
	margin-left: 1rem;
	justify-content: center;
	align-items: center;
	margin-top: 10vh;
}
