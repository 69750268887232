.contactUsForm {
	display: flex;
	flex-direction: column;
}

.imageHeader {
	position: fixed;
	object-fit: cover;
	width: 100vw;
	height: 90vh;
}

.button {
	margin-top: 5vh;
}
