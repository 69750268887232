/* Desktop Style */
.mainContainer {
	position: sticky;
	top: 0;
	backdrop-filter: blur(15px) brightness(100%);
	z-index: 100;
	max-width: none;
}

.menus {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin: 0;
}

.menuList {
	list-style: none;
}

.menuLink {
	font-family: "Bebas Neue";
	font-style: normal;
	font-weight: 400;
	font-size: 1.7rem;
	line-height: 100%;
	text-decoration: none;
	padding-inline: 30px;
	opacity: 0.5;
	color: var(--whites-primary);
}

.menuLink:visited {
	color: var(--whites-primary);
}

.menuLink:hover {
	color: var(--browns-primaryDark);
	opacity: 1;
}

.menuLinkSelected {
	font-family: "Bebas Neue";
	font-style: normal;
	font-weight: 400;
	font-size: 1.7rem;
	line-height: 100%;
	text-decoration: underline;
	padding-inline: 30px;
	opacity: 1;
	color: var(--browns-primaryLight);
}

.gdLogo {
	padding-inline: 64px;
	height: 50px;
	width: 5rem;
	cursor: pointer;
}

.lineSperator {
	border-left: 1px solid var(--browns-primaryLight);
	height: 10vh;
	width: 1px;
}

/* Mobile Style */
.mobileContainer {
	display: flex;
	flex: 1;
	flex-direction: row-reverse;
	align-items: center;
	justify-content: flex-start;
	height: 10vh;
}

.mobileMenuContainerActive {
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-end;
	height: 90vh;
	transition: all 0.5s ease;
}

.mobileMenuContainer {
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-end;
	height: 0vh;
	transition: all 0.5s ease;
	opacity: 0;
	pointer-events: none;
}

.mobileNavLogo {
	height: 50px;
	width: 10%;
	padding-inline: 1rem;
}

.mobileNavIcon {
	padding-inline: 30px;
	cursor: pointer;
}

.mobileNavIcon:hover {
	cursor: pointer;
}

.mobileSeparatorLine {
	border-left: 1px solid var(--browns-primaryLight);
	height: 7vh;
}

@media screen and (max-width: 1024px) {
	.menuLink {
		display: block !important;
		text-decoration: none;
		opacity: 0.5;
		color: var(--whites-primary);
		margin-top: 30px;
		font-family: "Bebas Neue";
		font-style: normal;
		font-weight: 400;
		font-size: 2.5rem;
		line-height: 100%;
	}

	.menuLink:hover {
		color: var(--browns-primaryDark);
		opacity: 1;
	}

	.menuLinkSelected {
		display: block !important;
		text-decoration: underline;
		opacity: 1;
		color: var(--browns-primaryLight);
		margin-top: 30px;
		font-family: "Bebas Neue";
		font-style: normal;
		font-weight: 400;
		font-size: 2.5rem;
		line-height: 100%;
	}
}
