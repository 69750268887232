.mainContainer {
	display: flex;

	width: 100%;
	height: 90vh;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.logoContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 25%;
	height: 25%;
}

.title {
	margin-top: 5rem;
	color: var(--browns-primaryLight);
}
