.mainContainer {
	display: flex;
	justify-content: center;
}

.rootContainer {
	display: flex;
	flex-direction: column;
	padding-top: 20vh;
}

.header {
	color: var(--browns-primaryLight);
}

.description {
	color: var(--whites-primary);
}

.gridView {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
	align-content: flex-start;
	width: 100%;
	gap: 1rem;
	margin-top: 3rem;
}

.listView {
	display: flex;
	flex-direction: column;
	width: 100%;
	flex-wrap: nowrap;
	margin-top: 3rem;
}
