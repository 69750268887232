.container {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--browns-primaryLight);
	border-radius: 30px;
	height: 50px;
	width: fit-content;
	padding-inline: 5%;
	cursor: pointer;
}

.container:active {
	opacity: 0.5;
}

.title {
	color: var(--blacks-primary);
	-moz-user-select: none;
	-khtml-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
	text-align: center;
	margin-left: 7.2px;
}
