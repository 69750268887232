.ctaContainer {
	background-color: var(--blacks-darker);
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	border-radius: 1rem;
	padding: 1rem;
}

.ctaMessageContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 1rem;
}

.ctaButtonContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-left: 0.1rem solid var(--browns-primaryLight);
	padding-top: 1rem;
	min-width: calc(50% - 6.358rem);
}

.estimateButton {
	flex: 1;
	text-decoration: underline;
	margin-top: 1.5rem;
	color: var(--browns-primaryLight);
	cursor: pointer;
	text-align: center;
}

.calendyButton {
	flex: 1;
	background-color: var(--browns-primaryLight);
	color: var(--blacks-primary);
	border: none;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	border-radius: 3rem;
	font-size: 1.8rem;
	font-family: Bebas Neue;
	font-weight: 400;
	letter-spacing: 1.5px;
	cursor: pointer;
	height: auto;
	width: auto;
}

.ctaMessage {
	color: var(--whites-primary);
	text-align: left;
}

.ctaContainerMobile {
	/* width: 100%; */
	background-color: var(--blacks-darker);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	border-radius: 1rem;
	padding: 2rem;
}

.ctaButtonContainerMobile {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-top: 0.1rem solid var(--browns-primaryLight);
	padding: 1rem;
	width: 90%;
	margin-top: 2rem;
	padding-top: 2rem;
}

.ctaMessageMobile {
	color: var(--whites-primary);
	text-align: center;
}
