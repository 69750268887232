.mainContainer {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	/* flex: 1; */
	margin: 1rem;
}

.image {
	height: 10rem;
	width: 10rem;
	border-radius: 5rem;
	object-fit: cover;
	overflow: hidden;
}

.name {
	margin-top: 1rem;
	text-align: center;
	color: var(--browns-primaryLight);
}

.position {
	margin-top: 1rem;
	text-align: center;
	color: var(--whites-primary);
	/* opacity: 0.5; */
}
