.mainContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 15vh;
}

.bodyText {
	color: var(--whites-primary);
}

.headerTitle {
	margin-bottom: 1rem;
	color: var(--browns-primaryLight);
}

.employeesContainer {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-evenly;
	width: 100%;
	margin-top: 4rem;
}
