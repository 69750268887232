.mainContainer {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	height: 40vh;
	margin-top: 10em;
	padding-inline: 10vw;
	border-top: 1px solid var(--whites-primary);
	backdrop-filter: blur(15px) brightness(50%);
	z-index: 100;
	opacity: 0.99;
}

.separatorLine {
	border-left: 1px solid var(--whites-primary);
	height: 13em;
	margin-inline: 3em;
	opacity: 0.5;
}

.gdLogo {
	height: 10em;
	opacity: 0.5;
}

.menus {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: flex-start;
	height: 13em;
	width: 10em !important;
}

.menuList {
	list-style: none;
}

.menuLink {
	text-decoration: none;
	opacity: 0.5;
	color: var(--whites-primary);
	font-family: "Bebas Neue";
	font-style: normal;
	font-weight: 400;
	font-size: 1.7rem;
	line-height: 100%;
}

.menuLink:visited {
	color: var(--whites-primary);
}

.menuLink:hover {
	color: var(--browns-primaryDark);
	opacity: 1;
}

.menuLinkSelected {
	text-decoration: underline;
	opacity: 1;
	color: var(--browns-primaryLight);
	font-family: "Bebas Neue";
	font-style: normal;
	font-weight: 400;
	font-size: 1.7rem;
	line-height: 100%;
}

.footNote {
	color: var(--whites-primary);
	font-family: "Catamaran";
	font-style: normal;
	font-weight: 400;
	font-size: 0.8rem;
	letter-spacing: 0.02rem;
	line-height: 150%;
}

.footNoteContainer {
	margin-inline: 2em;
}

.socialLinkContainer {
	display: flex;
	flex-direction: row;
}

.socialIcon {
	height: 2.5em;
	width: 2.5em;
	padding-inline: 0.5em;
}

.estimateTitle {
	letter-spacing: 3px;
	font-size: 1.5rem;
}

.estimateContainer {
	height: 40px;
	margin-top: 10px;
}

.parentSocialContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

@media screen and (max-width: 1024px) {
	.mainContainer {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 100%;
		padding-top: 5em;
		padding-bottom: 5em;
		padding-inline: 10vw;
		border-top: 1px solid var(--whites-primary);
		overflow: hidden;
	}

	.logoMenuContainer {
		display: flex;
		flex-direction: row;
		width: 100%;
		justify-content: space-evenly;
		align-items: center;
		height: 20vh;
	}

	.menus {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: flex-start;
		width: auto;
		height: 22vh;
	}

	.footNoteContainer {
		margin-top: 2em;
		margin-inline: 2em;
	}

	.socialLinkContainer {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		margin-top: 2em;
	}

	.estimateTitle {
		letter-spacing: 3px;
		font-size: 1rem;
	}

	.estimateContainer {
		width: 80%;
		height: 30px;
		margin-top: 10px;
	}
}
