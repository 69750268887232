.featuredProjects {
	margin-bottom: 10px;
	height: 80vh;
	cursor: pointer;
}

.secondFeaturedProject {
	margin-bottom: 10px;
	height: 45vh;
	cursor: pointer;
}

.gridView {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: flex-start;
	gap: 10px;
}
