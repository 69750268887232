.mainContainer {
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	background-color: var(--blacks-primary);
}

.contentContainer {
	width: 75%;
}

.headlineContainer {
	padding-top: 10vh;
}

.tagline {
	color: var(--whites-primary);
	width: auto;
	text-align: center;
}

.headlineTitle {
	color: var(--browns-primaryLight);
	text-align: center;
}

.datePosted {
	color: var(--whites-primary);
	width: 100%;
	text-align: center;
	opacity: 0.5;
}

.headerImageContainer {
	width: 100%;
	display: flex;
	justify-content: center;
	margin-top: 5vh;
	border-top: 0.1rem solid var(--browns-primaryLight);
}

.headerImage {
	width: 90%;
	height: 30vw;
	object-fit: cover;
}

.bodyText {
	color: var(--whites-primary);
	width: 100%;
	line-height: 180%;
}

@media screen and (max-width: 1024px) {
	.contentContainer {
		width: 95%;
	}

	.headerImage {
		width: 90%;
		height: 40vw;
		object-fit: cover;
	}
}
