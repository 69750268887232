.background {
	position: fixed;
	object-fit: cover;
	width: 100vw;
	height: 100vh;
}

.mainContainer {
	display: flex;
}
