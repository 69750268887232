.mainContainer {
	width: 100%;
}

.inputContainter {
	display: flex;
	flex-direction: row;
	align-items: center;
	background-color: var(--blacks-primary);
	padding: 1%;
	border-radius: 7px;
	min-height: 5rem;
}

.input {
	border: none;
	font-family: "Bebas Neue";
	font-style: normal;
	font-weight: 200;
	font-size: 1.8rem;
	text-align: left;
	background-color: transparent;
	color: var(--browns-primaryLight);
	outline: none;
	outline-color: transparent;
	width: 100%;
	min-height: 5rem;
	resize: vertical;
}

.fieldLabel {
	color: var(--whites-primary);
	margin-bottom: 5px;
}
