.container {
	flex: 0 0 calc(30% - 10px);
	height: calc(33.33vw - 10px);
	overflow: hidden;
	position: relative;
	cursor: pointer;
	align-items: center;
	justify-content: center;
	display: flex;
}

.mainContainer {
	display: flex;
	width: 100%;
	height: 100%;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	align-items: flex-end;
	border-radius: 1rem;
}

.blogTitleContainer {
	background-image: linear-gradient(
		to bottom,
		transparent,
		var(--blacks-primary)
	);
	height: 60%;
	width: 100%;
	justify-content: flex-end;
	display: flex;
	flex-direction: column;
	padding-bottom: 10%;
	padding-left: 5%;
	padding-right: 5%;
}

.header {
	text-align: left;
	color: var(--whites-primary);
	display: -webkit-box; /* Enables the flexbox layout */
	-webkit-box-orient: vertical; /* Specifies the orientation of the box */
	-webkit-line-clamp: 3; /* Number of lines to show before truncating */
	overflow: hidden;
}

.date {
	color: var(--browns-primaryDark);
}
