.mainContainers {
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	background-color: var(--blacks-primary);
}

.welcomeContainer {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	height: 100vh;
	width: 100vw;
	margin-top: -10vh;
}

.homeCover {
	object-fit: cover;
	width: 100%;
	height: 100%;
}

.overlay {
	position: absolute;
	top: 50vh;
	left: 0;
	right: 0;
	height: 50vh;
	background-image: linear-gradient(
		to bottom,
		transparent,
		var(--blacks-primary)
	);
	pointer-events: none; /* Allow clicks to pass through */
}

.headerTitle {
	color: var(--browns-primaryLight);
	letter-spacing: 1rem;
	text-align: center;
}

.tagline {
	color: var(--whites-primary);
	text-align: center;
	margin-top: 30vh;
}

.body {
	width: 75vw;
}

.bodyText {
	color: var(--whites-primary);
}

@media screen and (max-width: 1024px) {
	.welcomeContainer {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		height: 50vh;
		width: 100vw;
		margin-top: 0vh;
	}

	.overlay {
		position: absolute;
		top: 20vh;
		width: 100vw;
		height: 40vh;
		background-image: linear-gradient(
			to bottom,
			transparent,
			var(--blacks-primary)
		);
		pointer-events: none; /* Allow clicks to pass through */
	}

	.tagline {
		color: var(--whites-primary);
		text-align: center;
		margin-top: 40vh;
	}

	.headerTitle {
		color: var(--browns-primaryLight);
		letter-spacing: 0.5rem;
		text-align: center;
	}

	.body {
		width: 90vw;
		margin-top: 20vh;
	}
}
