.mainContainers {
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	background-color: var(--blacks-primary);
}

.welcomeContainer {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	height: 90vh;
}

.homeCover {
	object-fit: cover;
	width: 100vw;
	height: 90vh;
	margin-bottom: 1vh;
}

.welcomeHeader {
	color: var(--browns-primaryLight);
	letter-spacing: 0.35em;
	text-indent: 0.35em;
	position: absolute;
	align-self: center;
	margin-bottom: 0;
}

.welcomeBody {
	text-align: justify;
	text-justify: inter-word;
	color: var(--whites-primary);
}

.featureProjects {
	margin-bottom: 10px;
	height: 70vh;
	cursor: pointer;
}

.secondFeaturedProject {
	margin-bottom: 10px;
	height: 50vh;
	cursor: pointer;
}

.content {
	width: 75%;
}

.headercolor {
	color: var(--browns-primaryLight);
}

@media screen and (max-width: 1024px) {
	.welcomeContainer {
		display: flex;
		flex: 1;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		/* height: 90vh; */
		width: 100%;
	}

	.homeCover {
		object-fit: contain;
		width: 100%;
		height: 50vh;
		align-self: center;
		padding-top: 30vh;
		object-fit: cover;
		overflow: hiden;
	}

	.welcomeHeader {
		width: 100%;
		position: relative;
		color: var(--browns-primaryLight);
		letter-spacing: 0.35em;
		text-indent: 0.35em;
		margin-top: -10vh;
		align-self: center;
		text-align: center;
	}

	.welcomeBody {
		text-align: justify;
		text-justify: inter-word;
		color: var(--whites-primary);
	}

	.featureProjects {
		margin-bottom: 10px;
		height: 40vh;
		cursor: pointer;
	}

	.secondFeaturedProject {
		margin-bottom: 10px;
		height: 30vh;
		cursor: pointer;
	}

	.content {
		width: 95%;
	}
}
