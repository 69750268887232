.mainContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-top: 1rem;
	padding-bottom: 1rem;
	height: 25rem;
	cursor: pointer;
}

.mainContainerMobile {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	cursor: pointer;
}

.imageContainer {
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
	border-right: 0.1rem solid var(--browns-primaryLight);
	height: 100%;
}

.imageContainerMobile {
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
	border-bottom: 0.1rem solid var(--browns-primaryLight);
	width: 100%;
	height: 25rem;
}

.headerTitleContainerMobile {
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: flex-start;
	padding-top: 5%;
}

.headerTitleContainer {
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: flex-start;
	height: 100%;
	padding-top: 5%;
	margin-left: 1rem;
}

.image {
	width: 100%;
	height: 90%;
	object-fit: cover;
}

.imageMobile {
	width: 90%;
	height: 100%;
	object-fit: cover;
}

.tagLine {
	color: var(--whites-primary);
	text-align: left;
}

.headerTitle {
	color: var(--browns-primaryLight);
	text-align: left;
}

.shortDescription {
	color: var(--whites-primary);
	text-align: left;
}

.tagLineMobile {
	color: var(--whites-primary);
	text-align: center;
}

.headerTitleMobile {
	color: var(--browns-primaryLight);
	text-align: center;
}

.shortDescriptionMobile {
	color: var(--whites-primary);
	text-align: center;
}
