.blockquote {
	font-style: italic;
	font-family: "Catamaran";
	font-size: 1.2rem;
	font-weight: 300;
	line-height: 1.5rem;
	letter-spacing: 0.1rem;
	border-left: 5px solid var(--browns-primaryLight);
	margin: 20px 0;
	padding: 10px 20px;
	quotes: "“" "”"; /* Customize quote symbols */
}

.image {
	width: 100%;
	height: fit-content;
	object-fit: cover;
}
