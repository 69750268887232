.mainContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding-top: 20vh;
}

.imageContainer {
	flex: 1;
	min-width: calc(50% - 6.85rem);
	min-height: 50vh;
	padding-top: 2vh;
	padding-bottom: 5vh;
	border-right-width: 1px;
	border-right-style: solid;
	border-right-color: var(--browns-primaryLight);
}

.image {
	width: 100%;
	max-height: 50vh;
	object-fit: cover;
}

.tagline {
	color: var(--browns-primaryLight);
}

.header {
	color: var(--whites-primary);
	margin-top: 1vh;
	margin-bottom: 1vh;
}

.textContainer {
	flex: 2;
	padding-left: 3vw;
}

.bulletedText {
	color: var(--whites-primary);
	margin-top: 0%;
}

.entriesContainer {
	display: flex;
	flex-wrap: wrap;
	grid-column: 2;
	column-count: 2;
	justify-content: space-between;
	row-gap: 1.5rem;
}

.entryContainer {
	width: 20vw;
}

.entryHeader {
	color: var(--browns-primaryLight);
}

.entrySubHeader {
	color: var(--whites-primary);
}

@media screen and (max-width: 1024px) {
	.image {
		width: 100%;
		max-height: 30vh;
		object-fit: cover;
		margin-bottom: 3vh;
	}

	.textContainer {
		flex: 2;
		padding-left: 0;
	}

	.entryContainer {
		width: 30vw;
	}

	.entryContainer {
		width: 40vw;
	}
}
