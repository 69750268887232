.mainContainer {
	display: flex;
	backdrop-filter: blur(8px) brightness(90%);
	background-color: var(---blacks-primary-half-transparent);
	width: 50vw;
	margin-left: 10%;
	justify-content: center;
}

.contentContainer {
	display: flex;
	width: 100%;
	padding: 5%;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.headerTitle {
	color: var(--browns-primaryLight);
	text-align: left;
	width: 100%;
}

.notes {
	color: var(--whites-primary);
	text-align: left;
	width: 100%;
}

@media screen and (max-width: 1024px) {
	.mainContainer {
		display: flex;
		backdrop-filter: blur(8px) brightness(90%);
		background-color: var(---blacks-primary-half-transparent);
		width: 80vw;
		justify-content: center;
		align-items: center;
	}

	.totalContainer {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		padding: 5%;
	}

	.headerTitle {
		text-align: center;
	}

	.notes {
		color: var(--whites-primary);
		text-align: center;
	}
}
