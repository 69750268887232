.totalContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 5vh;
}

.totalLabels {
	letter-spacing: 0;
	margin-top: 5vh;
	color: var(--browns-primaryLight);
	text-align: right;
}

.notes {
	color: var(--whites-primary);
}

@media screen and (max-width: 1024px) {
	.totalContainer {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		margin-top: 5vh;
	}

	.totalLabels {
		font-size: 2.3rem;
		margin-top: 1vh;
	}

	.notes {
		color: var(--whites-primary);
		text-align: center;
	}
}
