.mainContainer {
	width: 100%;
}

.inputContainter {
	display: flex;
	flex-direction: row;
	align-items: center;
	background-color: var(--blacks-primary);
	padding-inline: 3%;
	border-radius: 7px;
}

.inputFieldRight {
	height: 4rem;
	border: none;
	font-family: "Bebas Neue";
	font-style: normal;
	font-weight: 200;
	font-size: 2.5rem;
	text-align: right;
	background-color: transparent;
	color: var(--browns-primaryLight);
	outline: none;
	outline-color: transparent;
	width: 100%;
}

.inputFieldLeft {
	height: 4rem;
	border: none;
	font-family: "Bebas Neue";
	font-style: normal;
	font-weight: 200;
	font-size: 2.5rem;
	text-align: left;
	background-color: transparent;
	color: var(--browns-primaryLight);
	outline: none;
	outline-color: transparent;
	width: 100%;
}

.fieldLabel {
	color: var(--whites-primary);
	margin-bottom: 5px;
}

.icon {
	width: 2.5rem;
	height: 2.5rem;
}

.suffix {
	color: var(--browns-primaryLight);
	font-size: 2rem;
	letter-spacing: 0;
	margin-left: 1rem;
}
