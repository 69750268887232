.container {
	display: flex;
	flex-direction: column;
	/* height: 10rem; */
	width: 100%;
	margin-bottom: 8vh;
	cursor: pointer;
}

.headerImage {
	width: 100%;
	height: 15rem;
	object-fit: cover;
	border-radius: 1rem;
}

.blogTitleContainer {
	padding-left: 2vw;
}

.header {
	color: var(--whites-primary);
	margin-top: 3vh;
}

.date {
	color: var(--browns-primaryDark);
}
